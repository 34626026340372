
@import url('https://fonts.googleapis.com/css2?family=Kenia&family=League+Spartan:wght@100&family=Lobster&family=Montserrat&family=Orbitron&family=Poppins:wght@100;200;300&family=Roboto&family=Rubik&family=Rubik+Mono+One&family=Russo+One&family=Staatliches&family=VT323&family=Vina+Sans&display=swap');
*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    
}


::-webkit-scrollbar{
    width: 0;
}

:root{
    --logo:black;
    --navlink:black;
    --navlinkh:grey;
}

h1{
    font-size: 4rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    
}
h4{
    font-family:Lato ;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
h6{
    font-weight: 700;
}

h5{
    font-size: 2rem;
    font-weight: 600;
}

h2{
    font-size: 3rem;
    font-weight: 800;

}

.main img{
    width:300px;
}

.navbar-brand{
    font-size: 2rem;
    color: var(--logo);
    font-family: 'Lobster', cursive;
}
.navbar-brand:hover{
    color: var(--logo);
}
.nav-link{
    margin-right: 10px;
    margin-left: 10px;
    color: var(--navlink);
    font-family: 'Rubik', sans-serif;
    font-size: 19px;
    text-transform: uppercase;
}
.nav-link:hover{
    color: var(--navlinkh);
    
}

.main{
    background-color: #8BC6EC;
    background-image: linear-gradient( 105.3deg,  rgba(30,39,107,1) 21.8%, rgba(77,118,221,1) 100.2% );

}
.main h1{
    font-size: 5rem;
    font-weight: 570;
    font-family: 'Rubik Mono One', sans-serif;
    color: white;
   
}
h1{
    font-size: 4rem;
    font-weight: 600;
    
}

.btn1{
   padding: 10px;
    margin-top: 7px;
    border: none;
    outline: none;
    background-color: #f0425f;
    color: white;
    font-weight: 700;
    border-radius: 60px ;
    text-decoration: none;
    font-size: 25px;
}
.btn1:hover{
    background-color:#ec8092;
    color: white;
}

.btn2{
    background-image: linear-gradient(135deg, #8BC6EC 0%, #b67fe7 100%);
    border-radius: 60px;
    height: 55px;
    width: 70%;
    color: white;
    font-size: 18px;
    font-weight: 900;
    border: none;
}
.btn2:hover{
 background: white;
 border: 2px solid  rgba(30,39,107,1) ;
 scale: 10vh;
}

 a:hover{
    color:  rgba(30,39,107,1) ;
}

.study{
    background-image: linear-gradient( 105.3deg,  rgba(30,39,107,1) 21.8%, rgba(77,118,221,1) 100.2% );
}

.study h1{
    font-weight: 800;
    color: white;
}

.btn3{
    height: 45px;
    width: 35%;
    border: none;
    outline: none;
    background-color:#f0425f;
    color: white;
    font-weight: 700;
    border-radius: 60px ;
}
.btn3:hover{
    background: white;
    border: 2px solid #f0425f;
    color:#f0425f;
    scale: 10vh;
}

.newsletter{
    background-color: black;
    color: white;
}

.newsletter input{
    height: 45px;
    width: 55%;
    border: 2px solid white;
    outline: none;
    color: white;
    background-color: transparent;
    margin-top: 20px;
    font-weight: 600;
}
.btn{
    height: 44.5px;
    width: 100px;
    background-color: white;
    margin-left: -10px;
    margin-bottom: 5px;
    font-weight: 600;
    border-radius: 0;
    color: black;
}
.btnnews{
    height: 44.9px;
    width: 100px;
    background-color: white;
    border: 1px solid white;
    margin-left: -10px;
    margin-bottom: 5px;
    font-weight: 600;
    border-radius: 0;
    color: black;
}
.footerlogo{
    margin-bottom: 10px;
    font-size: 2rem;
    color: white;
    font-family: 'Lobster', cursive;
}

.newsletter a{
    font-size: 1rem;
    margin: 0;
    color: white;
    text-decoration: none;
}

.fa-brands{
    font-size: 2rem;
    cursor: pointer;
    padding-right: 15px;
}
.features p{
    font-size: 20px;
}
.features img{
    width:"95%";
}

.headings{
    font-size: 4rem;
    font-weight: 800;}

    @media only screen and (max-width:1199px){

        .main h1{
            font-size: 3rem;
            font-weight: 400;
           
        }

        .features img{
            width: 300px;
        }

        .studyimg{
            width: 350px;
        }
        
}


    

    @media only screen and (max-width:480px){
        
        .join{
            font-size: 40px;
        }
        .main h1{
            font-size: 40px;
            font-weight: 400;
           
        }
        

        #features img{
            width: "90%"
        }

        .main img{
            width:250px;
        }

        .btn1{
            
             font-size: 15px;
             
         }
        }
        @media only screen and (max-width:320px){
           
        
            
            .ddd{
                font-size: 10px;
                font-weight: 100;
               
            }
    
            .features img{
                width: 200px;
            }
    
            .main img{
                width:250px;
            }
            }
    @media only screen and (min-width:912px){
                .studyimg{
                    width: 50px;
                }
            }
       
    