body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #fff;
  height: 100%;
  
}

.navbar {
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
}

.navbar-nav .nav-link {
  color: #333;
  margin-right: 1rem;
}

.navbar-nav .nav-link.active {
  color: #007bff;
  font-weight: bold;
}

.white {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjusting for the navbar height */
}

.background-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  
}

.color-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px; /* Width of the sidebar */
  background-color: #f0f0f0;
  border-right: 2px solid #007bff;
  padding: 1rem;
  box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.color-sidebar input[type="color"] {
  margin-bottom: 1rem;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;

}

.color-sidebar .button-29 {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.color-sidebar .button-29:hover {
  background-color: #0056b3;
}

.color-sidebar input[type="range"] {
  margin: 0.5rem 0;
  width: 100%;
}

.additional-colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.color-circle:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.whiteboard-canvas {
  flex: 1;
  background-color: white;
  border: 2px solid #007bff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor:crosshair;
}
/* Cursor styles for drawing */


/* Mobile view adjustments */
@media (max-width: 768px) {
  .background-container {
      flex-direction: column;
  }

  .color-sidebar {
      width: 100%;
      border-right: none;
      border-bottom: 2px solid #007bff;
      padding-bottom: 1rem;
  }

  .color-sidebar .button-29-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      gap: 0.5rem; /* Add space between buttons */
  }

  .color-sidebar input[type="range"] {
      margin-top: 1rem;
  }

  .additional-colors {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 1rem;
  }

  .color-circle {
      width: 25px;
      height: 25px;
      margin: 5px;
  }

  .whiteboard-canvas {
      height: 100vh; /* Increase height for smaller screens */
  }
}

@media (max-width: 480px) {
  .navbar-brand {
      font-size: 1.2rem;
  }

  .color-sidebar .button-29-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      gap: 0.5rem; /* Add space between buttons */
  }

  .color-sidebar .button-29 {
      font-size: 0.75rem;
      padding: 0.4rem 0.8rem;
  }

  .color-sidebar input[type="color"] {
      width: 30px;
      height: 30px;
  }

  .color-sidebar input[type="range"] {
      margin-top: 1rem;
  }

  .color-circle {
      width: 20px;
      height: 20px;
  }

  .whiteboard-canvas {
      height: 80vh; /* Increase height further for very small screens */
  }
}

